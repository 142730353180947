import { eventBus } from "@/eventBus";
const dashPlayer = {    
    _appconfigLocal: localStorage.getItem("appConfig") ? JSON.parse(localStorage.getItem("appConfig")) : '',
    methods: {
        initiateShaka(video) {   
            eventBus.$emit('playerLoader', true);
            window.shakaPlayer = new window.shaka.Player(video);
        },
        loadShakaPlayer(contentInformation, video) {
            let mpdURL = contentInformation?.streamfilename;
            // let licenseServerUrl = `https://vdrm.mobiotics.com/prodv4/proxy/v1/license/widevine`;
            let licenseServerUrl = this._appconfigLocal?.urls?.license?.widevine || "https://e7bpm1wyzl.execute-api.us-east-1.amazonaws.com/prodv5/proxy/v1/license/widevine";
            if (window.shakaPlayer) {
                window.shakaPlayer.configure({
                    drm: {
                        servers: {
                            'com.widevine.alpha': licenseServerUrl
                        },
                        advanced: {
                            'com.widevine.alpha': {
                                'videoRobustness': 'SW_SECURE_CRYPTO',
                            }
                        }
                    }
                });
                

                // Debug logs, when the default of INFO isn't enough:
                // window.shakaPlayer.setLevel(2);

                window.shakaPlayer.getNetworkingEngine().registerRequestFilter((type, request) => {
                    var StringUtils = shaka.util.StringUtils;
                    var Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;
                    let sessionToken = localStorage?.getItem('sessionToken');
                    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {
                        var rawLicenseRequest = new Uint8Array(request.body);
                        request.headers['Content-Type'] = "application/json";
                        request.headers["X-SESSION"] = sessionToken;
                        var wrapped = {};
                        wrapped.payload = btoa(String.fromCharCode.apply(null, rawLicenseRequest));
                        wrapped.contentid = contentInformation?.channelid ? contentInformation?.channelid : contentInformation?.contentid;
                        wrapped.providerid = 'keetv';
                        wrapped.timestamp = Math.floor((new Date()).getTime() / 1000);
                        wrapped.drmscheme = 'WIDEVINE';
                        var customData = {}
                        customData.packageid = contentInformation.packageid;
                        customData.drmtoken = contentInformation.drmToken.success;
                        // customData.drmtoken = "ciTawGaityhYeri";

                        wrapped.customdata = customData;

                        var wrappedJson = JSON.stringify(wrapped);

                        request.body = new Uint8Array(wrappedJson.length);
                        for (var i = 0; i < wrappedJson.length; ++i) {
                            request.body[i] = wrappedJson.charCodeAt(i);
                        }

                    }
                });

                window.shakaPlayer.getNetworkingEngine().registerResponseFilter(function (type, response) {


                    // console.log(response)
                    var StringUtils = shaka.util.StringUtils;
                    var Uint8ArrayUtils = shaka.util.Uint8ArrayUtils;
                    if (type == shaka.net.NetworkingEngine.RequestType.LICENSE) {

                        let contentType = response.headers['content-type'];
                        var wrappedArray = new Uint8Array(response.data);
                        var wrappedString = String.fromCharCode.apply(null, wrappedArray);
                        var wrapped = JSON.parse(wrappedString);

                        var rawLicenseBase64 = wrapped.body;

                        // decode it to a string.
                        var rawLicenseString = atob(rawLicenseBase64);
                        // convert that string into a Uint8Array and replace the response data to feed it to the Widevine CDM.
                        response.data = new Uint8Array(rawLicenseString.length);
                        for (var i = 0; i < rawLicenseString.length; ++i) {
                            response.data[i] = rawLicenseString.charCodeAt(i);
                        }

                    }
                });


                window.shakaPlayer.load(mpdURL).then(() => {
                    
                    video?.play();
                    eventBus.$emit('playerLoader', false);
                }).catch((onError) => {
                    console.error('Error code', onError.code, 'object', onError);
                    eventBus.$emit('playerError', onError.code);
                });

                window.shakaPlayer.addEventListener('buffering', (event) =>{
                    if (event.buffering) {
                      
                      eventBus.$emit('playerLoader', true);
                    } else {
                      
                      eventBus.$emit('playerLoader', false);
                    }
                });

                window.shakaPlayer.addEventListener('error', (error) => {
                    console.error('Error code', error.code, 'object', error);
                    // eventBus.$emit('playerError', 'unhandledNativeError');
                    if (error instanceof Error) {
                        // shaka crashed with an unhandled native error
                        eventBus.$emit('playerError', 'unhandledNativeError');
                    }
        
                    if (error.severity === shaka.util.Error.Severity.CRITICAL) {
                        // handle fatal error, playback can not continue
                        eventBus.$emit('playerError', 'playback can not continue');
                    } else {
                        // handle non-fatal error, playback can continue
        
                    }
                });

            }

        },

        destroyShaka() {
            if(window.shakaPlayer) {
                window.shakaPlayer.destroy().then(() => {
                    
                    window.shakaPlayer = null; // Clean up the reference
                });
            }
        }
    }
}

export default dashPlayer;