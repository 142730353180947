<template>
  <div>
    <div class="player__wrapper" tabindex="-1">
      <video class="my-video" id="live-channel-stream" autoplay @click="actClickParent()" tabindex="-1"
        type="application/x-mpegURL"></video>
      <div class="player-setting-outer" v-show="isPlayerSettingEnabled">
        <div class="player-setting">
          <p class="player-setting-outer-close" @click="actCloseSetting()">
            <img src="@/assets/live-channel/icons8-close-50.png" />
          </p>
          <div>
            <p>QUALITY</p>
            <ul>
              <li v-for="resolution of availableQualities" :key="resolution.title"
                @click="actSetResolution(resolution)">
                <p :style="resolution.selected ? { 'font-size': '1.3rem' } : ''">
                  {{ resolution.title }}
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p>AUDIO</p>
            <ul>
              <li></li>
            </ul>
          </div>
          <div>
            <p>SUBTITLES</p>
            <ul>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="player__controls" v-show="autoHideEnabled">
        <div class="player__controls_header" v-if="!channelListActive">
          <div class="player__controls_header_left live-logo-size hand" @click="actChannelList()" title="channels list">
            <ChanelListSvg :svgWnH="25" />
            <div class="player__controls_header_left_live" v-if="!channelListActive">
              <div @click="actReachedLive">
                LIVE
              </div>
            </div>
          </div>
          <div class="player__controls_header_right hand" v-if="!channelListActive">
            <p @click="actClosePlayer()" title="close player">
              <img src="@/assets/live-channel/icons8-close-50.png" />
            </p>

            <p>{{ timeFormatCode.strTime }}</p>
            <p>{{ timeFormatCode.yeardayformat }}</p>
          </div>
        </div>

        <div class="nextPrevChannel hand">
          <div @click="actPrevChannelChange" :style="{
            visibility:
              prevChannelDisplay && prevChannelDisplay.title
                ? 'visible'
                : 'hidden',
          }" class="nextPrevBlock tooltip-btns">
            <img src="@/assets/live-channel/prev-channel.png" class="nextPrevIcon" />
            <!-- <span style="padding: 0.4rem; max-width: 10rem">{{ prevChannelDisplay && prevChannelDisplay.title }}</span> -->
            <img :src="getLowPoster(prevChannelDisplay)" v-if="prevChannelDisplay" width="80px" height="60px"
              class="nextPrevPoster" />
            <Tooltip :toolTipText="'Previous Channel'" :position="'top'" class="tooltip-component"></Tooltip>
          </div>
          <div class="transparent" v-if="circularloading">
            <CircularLoader />
          </div>
          <div @click="actNextChannelChange" :style="{
            visibility:
              nextChannelDisplay && nextChannelDisplay.title
                ? 'visible'
                : 'hidden',
          }" class="nextPrevBlock tooltip-btns hand">
            <!-- <span style="padding: 0.4rem; max-width: 10rem">{{ nextChannelDisplay && nextChannelDisplay.title }}</span> -->
            <img :src="getLowPoster(nextChannelDisplay)" v-if="nextChannelDisplay" width="80px" height="60px"
              class="nextPrevPoster" />
            <img src="@/assets/live-channel/next-channel.png" class="nextPrevIcon" />
            <Tooltip :toolTipText="'Next Channel'" :position="'top'" class="tooltip-component"></Tooltip>
          </div>
        </div>

        <div class="player__controls_footer" v-if="!channelListActive">
          <img :src="getLowPoster(currentChannelDisplay)" v-if="currentChannelDisplay" width="120px" height="80px"
            class="current-poster" />
          <!-- <p class="currentChannelName">{{ currentChannelDisplay.title }}</p> -->
          <div class="progressBar" v-if="!channelListActive">
            <input class="slider" type="range" min="0" step="1" max="100" />
            <div class="player__controls_timer">
              <div>{{ secondsToHms(currentTime) }}</div>
              <div>{{ secondsToHms(totalDuration) }}</div>
            </div>
          </div>
          <div class="player__controls_footer_actions">
            <!-- <div class="player__controls_footer_left" v-if="liveplayerpayload.idepgchannel"> -->
            <div class="player__controls_footer_left">
              <p class="player__controls_footer_left_title program-text">
                {{ activeProgramData ? activeProgramData.title : "" }}
              </p>
              <p class="player__controls_footer_left_time program-text">
                {{
                  getFormatStartEndTime(activeProgramData)
                    ? getFormatStartEndTime(activeProgramData)
                    : ""
                }}
              </p>
              <p class="player__controls_footer_left_desc program-text">
                {{
                  activeProgramData
                    ? desriptionTranks(activeProgramData.desc)
                    : ""
                }}
              </p>
            </div>
            <div class="player__controls_footer_center">
              <div class="live-logo-size info hand" v-if="liveplayerpayload && liveplayerpayload.idepgchannel"
                @click="actClickInfoPage()">
                <img src="@/assets/live-channel/info.png" />
              </div>

              <div class="live-logo-size prevProgram hand" v-if="liveplayerpayload && liveplayerpayload.idepgchannel"
                @click="actChangeProgram('prev')">
                <img src="@/assets/live-channel/prev-channel.png" />
              </div>

              <div class="play-pause hand" v-if="isPlayActive">
                <img src="@/assets/live-channel/resume.png" height="70" @click="actPlay()" />
              </div>
              <div class="play-pause hand" v-else>
                <img src="@/assets/live-channel/pause.png" height="70" @click="actPause()" />
              </div>
              <div class="live-logo-size nextProgram hand" v-if="liveplayerpayload && liveplayerpayload.idepgchannel"
                @click="actChangeProgram('next')">
                <img src="@/assets/live-channel/next-channel.png" />
              </div>

              <!-- <div class="live-logo-size" v-if="liveplayerpayload && liveplayerpayload.idepgchannel">
                    <img src="@/assets/live-channel/creative-commons.png" />
                </div> -->
              <WatchList :content="liveplayerpayload" :svgWnH="30"
                class="quick-action-btn watch-list-btn flex-aline-center g-10 hand"
                v-if="liveplayerpayload && liveplayerpayload" />
            </div>
            <div class="player__controls_footer_right">
              <div class="volume">
                <input type="range" min="0" max="100" value="50" class="volume-range" />
                <div class="volume-icon hand" @click="actMuteUnmute(!ismuted)">
                  <!-- <img src="@/assets/live-channel/volume-up-4-48.png" /> -->
                  <VolumeIcon :status="ismuted ? 'mute' : 'unmute'" :dimensions="volumeIconDimensions" />
                </div>
                <div class="bar-hoverbox">
                  <div class="bar">
                    <div class="bar-fill"></div>
                  </div>
                </div>
              </div>
              <div class="play-pause-mobile" v-if="isPlayActive">
                <img src="@/assets/live-channel/resume.png" height="70" @click="actPlay()" />
              </div>
              <div class="play-pause-mobile" v-else>
                <img src="@/assets/live-channel/pause.png" height="70" @click="actPause()" />
              </div>
              <div @click="actPlayerSetting" class="settings live-logo-size hand">
                <img src="@/assets/live-channel/setting.png" />
              </div>
              <div @click="actPlayerFullscreen" class="full-Screen live-logo-size hand">
                <img src="@/assets/live-channel/fullscreen_white.svg" />
              </div>
            </div>
          </div>
        </div>
        <div class="player__control_chanels_list" v-if="channelListActive">
          <div class="playList_container">
            <div class="playList_header">
              <div class="header_title">
                {{ 'ChannelList' }}
              </div>
              <div class="close_play_list" @click="channelListActive = false">
                <ClosePopup />
              </div>
            </div>
            <div class="playList_body">
              <ChannelList :chanelCategoryData="chanelCategoryData" :activeChanelProgramsList="activeChanelProgramsList"
                @actFromChannelList="actFromChannelList" />
            </div>
          </div>
        </div>
      </div>
      <div class="epgInfoModal" v-if="setEpgProgramModal">
        <div class="modal-container">
          <div class="modal-header">
            <div class="close-modal">
              <p @click="setEpgProgramModal = false"><img src="@/assets/live-channel/icons8-close-50.png" /> </p>
            </div>
          </div>
          <div class="modal-body">
            <ProgramContainer :activeProgramData="activeChannelProgram" @actSelectedProgram="actSelectedProgram"/>
          </div>
        </div>
      </div>
    </div>
    <div class="error-message blurred" v-if="errorMessage">
      <div class="error-message-content">
        <div class="error-message-header">
          <div class="error-message-title">
            {{ 'Error' }}
          </div>
          <!-- <div class="error-message-icon">
            <img src="@/assets/live-channel/error.png" />
          </div> -->
        </div>
        <hr />
        <div class="error-message-body">
          <div class="error-message-text">
            {{ errorMessage ? errorMessage : 'Unknown error' }}
          </div>
        </div>
        <div class="error-message-footer">
          <div class="error-message-btns btn_one" @click="actNextChannelChange">
            {{ 'Next Channel' }}
          </div>
          <div class="error-message-btns btn_two" @click="actClosePlayer()">
            <span> {{ 'Close Player' }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
        
<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import PlaybackUtilities from "@/mixins/playbackUtilities.js";
  import dashPlayer from "../../mixins/dashPlayer";
  import fairPlayer from "../../mixins/fairPlayer";
  import Utility from "@/mixins/Utility.js";
  import { eventBus } from "@/eventBus";
  import { store } from "@/store/store";
  import { _providerId } from "@/provider-config.js";
  import { showSnackBar } from "@/utilities";

  export default {
    computed: {
      ...mapGetters(["appConfig"]),
    },
    components: {
      WatchList: () => import(/* webpackChunkName: "watchList" */ "@/components/SvgImages/WatchList.vue"),
      ChanelListSvg: () => import(/* webpackChunkName: "chanelListSvg" */ "@/components/SvgImages/ChanelListSvg.vue"),
      ProgramContainer: () => import(/* webpackChunkName: "programContainer" */ "@/components/Epg/EpgProgramContainer.vue"),
      ClosePopup: () => import(/* webpackChunkName: "closePopup" */ "@/components/SvgImages/ClosePopup.vue"),
      ChannelList: () => import(/* webpackChunkName: "channelList" */ "@/components/LivePlayer/ChannelList.vue"),
      CircularLoader: () => import(/* webpackChunkName: "circularLoader" */ "@/components/Loaders/CircularLoader.vue"),      
      VolumeIcon: () => import(/* webpackChunkName: "volumeIcon" */ "@/components/SvgImages/Volume.vue"),
      Tooltip: () => import(/* webpackChunkName: "tooltip" */ "@/components/Text/ToolTip.vue"),
    },
    data() {
      return {
        liveplayerpayload: null,
        urlbase: "",
        hlsVideo: "",
        isPlayActive: true,
        currentTime: 0,
        totalDuration: 0,
        timeFormatCode: {},
        width: "50%",
        activeProgramData: null,
        autoHideEnabled: true,
        currentProgremIndex: 0,
        allProgramsData: null,
        isPlayerSettingEnabled: false,
        availableQualities: [],
        activeChanelProgramsList: null,
        placeHolder: {
          portrait: require("@/assets/icons/PlaceHolderPortrait.png"),
          landScape: require("@/assets/icons/PlaceHolderLandscape.png"),
          square: require('@/assets/icons/PlaceHolderSquare.png')
        },
        channelListActive: false,
        selectedChannelIndex: 0,
        loaderHeight: "2.5rem",
        nextChannelDisplay: {},
        currentChannelDisplay: {},
        prevChannelDisplay: {},
        activeChanelProgramsListAll: [],
        totalAllCategorycount: 0,
        setEpgProgramModal: false,
        activeChannelProgram: null,
        totalChannelcount: null,
        encryptedPayload: {},
        isLivePlayerHeaderTokenNeed: true,
        circularloading: false,        
        ismuted: false,
        Mute: require('@/assets/icons/mute.png'),
        Unmute: require('@/assets/icons/unmute.png'),
        volumeIconDimensions: {width:'30', height:'30'},
        errorMessage: null,
      };
    },
    props: ["liveplayerpayloadSingle", "programPayload"],
    created() {
      window.selectedGenre = this.liveplayerpayloadSingle.genre;
      // eventBus.$on("channelListData", (payLoad) => {
      //   this.listGenerContent(payLoad).then((data) => {
      //     data.data.data["TVCHANEL"].map((item, i) => {
      //       this.chanelCategoryData = [
      //         ...this.chanelCategoryData,
      //         ...Object.keys(item),
      //       ];
      //     });
      //     let arr = ["genre", "subgenre"];
      //     window.currentPageIndex = 1;        
      //     window.selectedGenre = this.liveplayerpayloadSingle.genre;
      //     payLoad.page = window.currentPageIndex;
      //     if (this.liveplayerpayloadSingle.genre) {
      //       payLoad.genre = this.liveplayerpayloadSingle.genre;
      //       payLoad.subgenre = this.liveplayerpayloadSingle.genre;
      //       payLoad.groupbyor =  JSON.stringify(arr);
      //     };
      //     window.activeChanelProgramsListAll = [];
      //     this.activeProgramsDate(payLoad);
      //   });
      // });
      eventBus.$on("playerInitialization", (data) => {
        this.liveplayerpayload = data;
        if(this.liveplayerpayload && this.liveplayerpayload.idepgchannel) {
          this.epgAPICall()
        }
        this.playerInit();
        if(window.activeChanelProgramsListAll.length > 0){
            this.getAlltheChanelPagination();
          }
      });
      eventBus.$on('playerLoader', (data) => {
        // debugger
        this.circularloading = data;
      });
      eventBus.$on('playerError', (data) => {
        this.errorMessage = data;
      });
    },
    methods: {
      ...mapActions([
        "mapEpgXmlData",
        "listContent",
        "listGenerContent",
        "getContent",
      ]),
  
      actNextChannelChange() {
        this.errorMessage = null;
        this.circularloading = true;
        this.discardHls();
        this.destroyShaka();
        let found = false;
        this.isPlayActive = true;
        let Num = 0;
  
        for (let index in window.activeChanelProgramsListAll) {
          Num = Number(index);
  
          if (
            window.activeChanelProgramsListAll[Num].objectid ==
            this.currentChannelDisplay.objectid
          ) {
            found = true;
            this.nextChannelDisplay = window.activeChanelProgramsListAll[Num + 2]
              ? window.activeChanelProgramsListAll[Num + 2]
              : {};
            this.prevChannelDisplay = window.activeChanelProgramsListAll[Num]
              ? window.activeChanelProgramsListAll[Num]
              : {};
            this.currentChannelDisplay = window.activeChanelProgramsListAll[
              Num + 1
            ]
              ? window.activeChanelProgramsListAll[Num + 1]
              : {};
  
            break;
          }
        }
        // this.initiateHls();
        this.actProgramPlay(this.currentChannelDisplay);
  
        // window.totalAllCategorycount = item.data.totalCount;
        //   window.currentPageIndex = 1;
        //   window.activeChanelProgramsListAll 
  
        if (
          !window.activeChanelProgramsListAll[Num + 4] &&
          window.activeChanelProgramsListAll.length != window.totalAllCategorycount
        ) {
  
          window.currentPageIndex = window.currentPageIndex + 1;
          let payload = {
                'objecttype' : 'CHANEL',
                page: window.currentPageIndex
              }
          let arr = ["genre", "subgenre"];
          if (window.selectedGenre !== 'ALL CATEGORIES') {
              payload.genre = window.selectedGenre;
              payload.subgenre = window.selectedGenre;
              payload.groupbyor =  JSON.stringify(arr);
          }
            this.listContent(payload).then((item) => {
              window.totalAllCategorycount = item.data.totalCount;
              window.activeChanelProgramsListAll = [...window.activeChanelProgramsListAll, ...item.data.data];
            })
        
         
          
        }
      },
      actPrevChannelChange() {
        // debugger
        this.errorMessage = null;
        this.circularloading = true;        
        this.discardHls();
        this.destroyShaka();
        this.isPlayActive = true;


        for (let index in window.activeChanelProgramsListAll) {
          let Num = Number(index);
          if (
            window.activeChanelProgramsListAll[Num].objectid ==
            this.currentChannelDisplay.objectid
          ) {
            this.nextChannelDisplay = window.activeChanelProgramsListAll[Num]
              ? window.activeChanelProgramsListAll[Num]
              : {};
            this.prevChannelDisplay = window.activeChanelProgramsListAll[Num - 2]
              ? window.activeChanelProgramsListAll[Num - 2]
              : {};
            this.currentChannelDisplay = window.activeChanelProgramsListAll[
              Num - 1
            ]
              ? window.activeChanelProgramsListAll[Num - 1]
              : {};
  
            break;
          }
        }
        // this.initiateHls();
        this.actProgramPlay(this.currentChannelDisplay);
      },
      desriptionTranks(desc) {
        if (desc && desc.length > 100) {
          return desc.substring(0, 100) + "...";
        } else {
          return desc;
        }
      },
      actPlayerSetting() {
        this.isPlayerSettingEnabled = !this.isPlayerSettingEnabled;
      },
      actPlayerFullscreen() {
        if (this.isActiveFullScreen) {
          this.actPlayerExitFullScreen();
          this.isActiveFullScreen = false;
          return;
        }
        this.isActiveFullScreen = true;
        var elem = document.getElementsByClassName("player__wrapper")[0];
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      },
      actPlayerExitFullScreen() {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      },
      actCloseSetting() {
        this.isPlayerSettingEnabled = false;
      },
      actSetResolution(quality) {
        this.updateQuality(quality);
      },
      actReachedLive() {
        this.hlsVideo.currentTime = this.hlsVideo.duration - 1;
      },
      actChangeProgram(status) {
        if (status == "next") {
          if (this.allProgramsData && this.allProgramsData.length - 1 == this.currentProgremIndex) return;
          this.activeProgramData =
            this.allProgramsData[++this.currentProgremIndex];
        } else {
          if (this.currentProgremIndex == 0) return;
          this.activeProgramData =
            this.allProgramsData[--this.currentProgremIndex];
        }
      },
      actClosePlayer() {
        this.destroyShaka();
        this.discardHls();
        this.$emit("actCloseLivePlayer", true);
        this.errorMessage = null;
      },
      actClickInfoPage() {
        // this.actClosePlayer();
        let currentLanguage = this.getCurrentLanguageUrlBase();
        let idepgchannel = this.liveplayerpayload.idepgchannel;
        this.setEpgProgramModal = true;
        // this.$router.push({name: 'Epg', params: { lang: currentLanguage }, query: {channel: idepgchannel} });
        this.activeChannelProgram = this.allProgramsData;
        // this.$router.push({
        //   name: "Epg",
        //   params: { lang: currentLanguage },
        //   query: { channel: idepgchannel },
        // });
      },
      actClickParent() {
        if (this.hlsVideo.paused) {
          this.actPause();
        } else {
          this.actPlay();
        }
      },
      async epgAPICall() {
        const payload = {};
        let date = new Date().toISOString().split("T")[0];
  
        let s = moment(date + " 00:00:00")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        let e = moment(date + " 23:59:59")
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
  
        payload.start = s;
        payload.stop = e;
        payload.idepgchannel = this.liveplayerpayload.idepgchannel
          ? JSON.stringify([this.liveplayerpayload.idepgchannel])
          : null;
        let res = await this.mapEpgXmlData(payload);
        this.allProgramsData = window.xmlToJson.parse(res.data).tv.programme;
        this.allProgramsData && this.allProgramsData.filter((program, i) => {
          if (this.isBetween(program)) {
            this.activeProgramData = program;
            this.currentProgremIndex = i;
          }
        });
      },
      formatAMPM() {
        let date = new Date();
  
        var day = date.getDate();
        var year = date.getFullYear();
        var month = date.getMonth();
  
        this.timeFormatCode.yeardayformat = day + "-" + (month + 1) + "-" + year;
  
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + ampm;
        this.timeFormatCode.strTime = strTime;
      },
      secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor((d % 3600) / 60);
        var s = Math.floor((d % 3600) % 60);
  
        var hDisplay = h > 0 ? (h < 10 ? "0" + h + ":" : h + ":") : "00:";
        var mDisplay = m > 0 ? (m < 10 ? "0" + m + ":" : m + ":") : "00:";
        var sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
  
        return hDisplay + mDisplay + sDisplay;
      },
      actPause() {
        this.hlsVideo.play();
        this.isPlayActive = true;
        this.actAutoHideEnabled(false);
      },
      actPlay() {
        this.hlsVideo.pause();
        this.isPlayActive = false;
        this.autoHideEnabled = true;
        this.actAutoHideEnabled(true);
      },
      actAutoHideEnabled(res) {
        if (this.hlsVideo.paused ) {
          this.autoHideEnabled = true;
          return;
  
        }
        this.autoHideEnabled = res;
      },
      actAutoHideEnabledMouse(res) {
        this.autoHideEnabled = res;
  
        if (this.hlsVideo.paused) {
          this.autoHideEnabled = true;
          return;
        }
   
  
        setTimeout(() => {
            if (this.hlsVideo.paused) {
              this.autoHideEnabled = true;
              return;
             }
          this.autoHideEnabled = !res;
        }, 4000);
      },
      debounceFunction(func, timeout = 1000) {
        let timer;
        return (...args) => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            func.apply(this, args);
          }, timeout);
        };
      },
      throttleFunction(func, delay) {
        let prev = 0;
        return (...args) => {
          let now = new Date().getTime();
          if (now - prev > delay) {
            prev = now;
            return func(...args);
          }
        };
      },
      async playerInit (isDrm, isIosEnabled) {
        // let temporaryVideoTag = document.createElement("video");
        // let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");
        // let isAndroid = navigator.userAgent.includes("Android");
        if(isDrm) {
          if (isIosEnabled) {
            // this.initiateHls(this.hlsVideo);
            this.initiateFairPlay(this.encryptedPayload, this.hlsVideo);
          } else {
            // alert("Error");
            this.initiateShaka(this.hlsVideo);
            this.loadShakaPlayer(this.encryptedPayload, this.hlsVideo);
          }
        } else {
            if (window.Hls.isSupported()) {
              this.channelListActive = false;

                if (this.isLivePlayerHeaderTokenNeed) {
                    window.hlsPlayerObj = new window.Hls({
                      xhrSetup: xhr => {
          
                        xhr.setRequestHeader('drmtoken', this.encryptedPayload.drmtoken)
                        xhr.setRequestHeader('packageid', this.encryptedPayload.packageid)
                        xhr.setRequestHeader('contentid', this.encryptedPayload.contentid)
                        xhr.setRequestHeader('providerid', this.encryptedPayload.providerid)
          
                      }
                      }
                    );
                }
      
      
                // bind them together
                window.hlsPlayerObj.attachMedia(this.hlsVideo);  
                // alert(this.liveplayerpayload.streamUrl);      
                window.hlsPlayerObj.on(window.Hls.Events.MEDIA_ATTACHED, (event, data) => {
                  window.hlsPlayerObj.loadSource(this.liveplayerpayload.streamUrl);
                  // window.hlsPlayerObj.loadSource("https://stream3.keeott.com/bpk-tv/COLORS_INFINITY/output/index.m3u8");
                });
                this.actAutoHideEnabled(false);
                window.hlsPlayerObj.on(window.Hls.Events.MANIFEST_PARSED, (event, data) => {
                    // this.hlsVideo.play();
                    this.availableQualities = [];
      
                    let availableQualities = window.hlsPlayerObj.levels.map(
                      (l) => l.height
                    );
                    availableQualities.forEach((resolution, index) => {
                      this.availableQualities.push({
                        selected: false,
                        key: resolution,
                        title: resolution + "p",
                        index: index + 1,
                      });
                    });
      
                    this.availableQualities.unshift({
                      selected: true,
                      key: "Auto",
                      title: "Auto",
                      index: 0,
                    });
                  }
                );

                window.hlsPlayerObj.on(window.Hls.Events.BUFFER_APPENDING, () =>{
                  this.circularloading = true;
                })
                window.hlsPlayerObj.on(window.Hls.Events.BUFFER_APPENDED, () =>{
                  this.circularloading = false;
                });
                window.hlsPlayerObj.on(window.Hls.Events.ERROR, function (event, data) {
                  const errorType = data.type;
                  const errorDetails = data.details;
                  const errorFatal = data.fatal;

                  if (errorFatal) {
                    switch (errorType) {
                      case window.Hls.ErrorTypes.NETWORK_ERROR:
                        console.error('Fatal network error encountered:', data);
                        this.errorMessage = data.details;
                        // Handle fatal network error (e.g., retry policies, user notifications)
                        // Immediate retries can cause loops, implement retry logic accordingly
                        break;

                      case window.Hls.ErrorTypes.MEDIA_ERROR:
                        console.warn('Fatal media error encountered, attempting recovery');
                        this.errorMessage = data.details;
                        window.Hls.recoverMediaError();
                        break;

                      default:
                        // Handle other fatal errors (e.g., destroy player, user notifications)
                        console.error('Fatal error encountered:', data);
                        this.errorMessage = data.details;
                        window.Hls.destroy();
                        break;
                    }
                  } else {
                    // Handle non-fatal errors
                    switch (errorDetails) {
                      case window.Hls.ErrorDetails.MANIFEST_LOAD_ERROR:
                        console.error('Manifest load error:', data);
                        // Handle manifest load error (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.MANIFEST_LOAD_TIMEOUT:
                        console.error('Manifest load timeout:', data);
                        // Handle manifest load timeout (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.MANIFEST_PARSING_ERROR:
                        console.error('Manifest parsing error:', data);
                        // Handle manifest parsing error (e.g., show error to user)
                        break;

                      case window.Hls.ErrorDetails.LEVEL_LOAD_ERROR:
                        console.error('Level load error:', data);
                        // Handle level load error (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.LEVEL_LOAD_TIMEOUT:
                        console.error('Level load timeout:', data);
                        // Handle level load timeout (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.LEVEL_SWITCH_ERROR:
                        console.error('Level switch error:', data);
                        // Handle level switch error (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.FRAG_LOAD_ERROR:
                        console.error('Fragment load error:', data);
                        // Handle fragment load error (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.FRAG_LOAD_TIMEOUT:
                        console.error('Fragment load timeout:', data);
                        // Handle fragment load timeout (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.BUFFER_APPEND_ERROR:
                        console.error('Buffer append error:', data);
                        // Handle buffer append error (e.g., clearing buffer, retry logic)
                        break;

                      case window.Hls.ErrorDetails.BUFFER_APPENDING_ERROR:
                        console.error('Buffer appending error:', data);
                        // Handle buffer appending error (e.g., clearing buffer, retry logic)
                        break;

                      case window.Hls.ErrorDetails.BUFFER_STALLED_ERROR:
                        console.warn('Buffer stalled error:', data);
                        // Handle buffer stalled error (e.g., retry logic)
                        break;

                      case window.Hls.ErrorDetails.INTERNAL_EXCEPTION:
                        console.error('Internal exception:', data);
                        // Handle internal exception (e.g., logging, retry logic)
                        break;

                      default:
                        // Handle other non-fatal errors
                        console.warn('Non-fatal error encountered:', data);
                        break;
                    }
                  }
                });
                
              // });
            }
        }
      },
      updateQuality(newQuality) {
        // this.isPlayerSettingEnabled = false;
        this.availableQualities.forEach((resolution) => {
          resolution.selected = false;
        });
  
        if (newQuality.key === "Auto") {
          window.hlsPlayerObj.currentLevel = -1; //Enable AUTO quality if option.value = 0
          this.availableQualities[0].selected = true;
        } else {
          window.hlsPlayerObj.currentLevel = newQuality.index - 1;
          this.availableQualities[newQuality.index].selected = true;
        }
      },
      isBetween(item) {
        // let currentTime = new Date();
        let currentTime = moment.utc().local().format("YYYY-MM-DD HH:mm:ss");
        let today = moment.utc(moment(currentTime)).local().format("YYYY-MM-DD");
        currentTime = moment(currentTime).format("HH:mm");
        let startTime = moment
          .utc(moment(item.start, "YYYYMMDDHHmmss Z"))
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        let startDay = moment(startTime).format("YYYY-MM-DD");
        startTime = moment(startTime).format("HH:mm");
        let endTime = moment
          .utc(moment(item.stop, "YYYYMMDDHHmmss Z"))
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        let h = currentTime.split(":")[0];
        let m = currentTime.split(":")[1];
        let h1 = startTime.split(":")[0];
        let m1 = startTime.split(":")[1];
        let h2 = endTime.split(":")[0];
        let m2 = endTime.split(":")[1];
        return (
          (h1 < h || (h1 == h && m1 <= m)) && (h < h2 || (h == h2 && m < m2))
        );
      },
      getFormatStartEndTime(item) {
        if (!item) return;
  
        let startTime = moment
          .utc(moment(item.start, "YYYYMMDDHHmmss Z"))
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        startTime = moment(startTime).format("HH:mm");
        let endTime = moment
          .utc(moment(item.stop, "YYYYMMDDHHmmss Z"))
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
        endTime = moment(endTime).format("HH:mm");
        return startTime + " - " + endTime;
      },
      actChannelList () {
        this.channelListActive = true;
      },
      getLowPoster(item) {
        let returnImage = this.placeHolder.landScape;
        let index =
          item &&
          item.poster &&
          item.poster.findIndex((data) => {
            return data.postertype === "LANDSCAPE";
          });
        if (index > -1) {
          item.poster[index].filelist.filter((lowImg) => {
            if (lowImg && lowImg.quality === "LOW") {
              returnImage = lowImg.filename;
            }
          });
        }
        //  else {
        //   debugger;
        //   item.poster[0].filelist.filter((el) => {
        //     if (el.quality === "THUMBNAIL") {
        //       returnImage = el.filename;
        //     }
        //   });
        // }
        return returnImage;
      },
      // actChannelSelect(chanel, i) {
      //   this.selectedChannelIndex = i;
      //   this.activeChanelProgramsList = null;
      //   let payLoad = {
      //     objecttype: "CHANEL",
      //   };
      //   let arr = ["genre", "subgenre"];
      //   if (i !== 0) {
      //     payLoad.genre = chanel;
      //     payLoad.subgenre = chanel;
      //     payLoad.groupbyor =  JSON.stringify(arr);
      //   }
      //   this.activeProgramsDate(payLoad);
      // },
      getAlltheChanelPagination (type = null) {
        for (let index in window.activeChanelProgramsListAll) {
          let Num = Number(index);
          if (
            window.activeChanelProgramsListAll[Num].objectid ==
            this.liveplayerpayload.objectid
          ) {
            this.currentChannelDisplay = window.activeChanelProgramsListAll[Num]
              ? window.activeChanelProgramsListAll[Num]
              : {};
            this.nextChannelDisplay = window.activeChanelProgramsListAll[Num]
              ? window.activeChanelProgramsListAll[Num + 1]
              : {};
            this.prevChannelDisplay = window.activeChanelProgramsListAll[Num]
              ? window.activeChanelProgramsListAll[Num - 1]
              : {};
            break;
          }
        }
      },
      activeProgramsDate (payload) {
        this.listContent(payload).then((singleChanelData) => {
          this.activeChanelProgramsList = [];
          this.activeChanelProgramsList = [
            ...this.activeChanelProgramsList,
            ...singleChanelData.data.data,
          ];
          window.activeChanelProgramsListAll = [...window.activeChanelProgramsListAll, ...this.activeChanelProgramsList];
          this.totalChannelcount = singleChanelData.data.totalcount;
          window.totalChannelcount = this.totalChannelcount;
          if(window.activeChanelProgramsListAll.length > 0){
            this.getAlltheChanelPagination();
          }
        });
      },
     
      async actProgramPlay (program) {
        eventBus.$emit("loader-player-show", true);
        this.circularloading = true;
            this.channelListActive = false;
              this.liveplayerpayload = program;
              
              // eventBus.$emit('playerInitialization', program);
              if (this.isLivePlayerHeaderTokenNeed) {

                let temporaryVideoTag = document.createElement("video");
                let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");
                let isAndroid = navigator.userAgent.includes("Android");

                let isIosEnabled = (!isAndroid && hasNativeHlsSupport != "") ;
                var urlencoded = new URLSearchParams();
                urlencoded.append("contentid", program.channelid ? program.channelid : program.objectid);
                urlencoded.append("ios", isIosEnabled);
                if (isIosEnabled == true) {
                  urlencoded.append("offline", "YES");
                }
                urlencoded.append("seclevel", "SW");
                store.dispatch("actGetDRMToken", urlencoded).then((res) => {
                  if(res?.data?.reason){
                    this.errorMessage = res?.data?.reason;
                    return;
                  }
                  this.encryptedPayload = res?.data;
                  this.encryptedPayload.contentid = program.objectid;
                  this.encryptedPayload.channelid = program.channelid;
                  if(this.liveplayerpayloadSingle?.program && this.liveplayerpayloadSingle?.program.start && this.liveplayerpayloadSingle?.program.stop){
                  // In future we have to add startdate and stop date
                    let programStart = moment.utc(moment(this.liveplayerpayloadSingle?.program.start, "YYYYMMDDHHmmss Z")).format("YYYY-MM-DD HH:mm:ss");
                    let startDay = moment(programStart).format("YYYYMMDD");
                    let startTime = moment(programStart).format("HHmmss");
                    let programEnd  = moment.utc(moment(this.liveplayerpayloadSingle?.program.stop, "YYYYMMDDHHmmss Z")).format("YYYY-MM-DD HH:mm:ss");
                    let endDay = moment(programEnd).format("YYYYMMDD");
                    let endTime = moment(programEnd).format("HHmmss");
                    this.encryptedPayload.streamfilename = `${this.encryptedPayload.streamfilename}?begin=${startDay}T${startTime}&end=${endDay}T${endTime}`;
                  } else if(this.liveplayerpayloadSingle?.contentprovider == "CATCHUPTV" && this.liveplayerpayloadSingle?.publishtime && this.liveplayerpayloadSingle.endtime) {
                    let programStart = moment.utc(moment(this.liveplayerpayloadSingle?.publishtime, "YYYY-MM-DD HH:mm:ss")).local().format("YYYY-MM-DD HH:mm:ss");
                    let startDay = moment(programStart).format("YYYYMMDD");
                    let startTime = moment(programStart).format("HHmmss");
                    let programEnd  = moment.utc(moment(this.liveplayerpayloadSingle?.endtime, "YYYY-MM-DD HH:mm:ss")).local().format("YYYY-MM-DD HH:mm:ss");
                    let endDay = moment(programEnd).format("YYYYMMDD");
                    let endTime = moment(programEnd).format("HHmmss");
                    this.encryptedPayload.streamfilename = `${this.encryptedPayload.streamfilename}?begin=${startDay}T${startTime}&end=${endDay}T${endTime}`;
                  }
                  this.playerInit(true, isIosEnabled);
                  if(this.liveplayerpayload && this.liveplayerpayload.idepgchannel) {
                    this.epgAPICall()
                  }
                    // let payLoad = {
                    //   objecttype: "CHANEL",
                    // };
                    // eventBus.$emit("channelListData", payLoad);
                }).catch((err) => {
                  alert('Drm Error')
                  console.error("err >>>>", err)
                });
              } else {
                // this.initiateHls();
                this.playerInit(false);  
              }        
              if(window.activeChanelProgramsListAll.length > 0){
                this.getAlltheChanelPagination();
              }
              eventBus.$emit("loader-player-show", false);
      },
      setupVolumn () {
        const range = document.querySelector(".volume input[type=range]");
  
        const barHoverBox = document.querySelector(".volume .bar-hoverbox");
        const fill = document.querySelector(".volume .bar .bar-fill");
  
        range.addEventListener("change", (e) => {
          console.log("value", e.target.value);
        });
  
        const setValue = (value) => {
          fill.style.width = value + "%";
          range.setAttribute("value", value);
          document.getElementById("live-channel-stream").volume = value / 100;
        };
        setValue(range.value);
  
        const calculateFill = (e) => {
          let offsetX = e.offsetX;
          if (e.type === "touchmove") {
            offsetX = e.touches[0].pageX - e.touches[0].target.offsetLeft;
          }
  
          const width = e.target.offsetWidth - 30;
  
          setValue(
            Math.max(
              Math.min(
                ((offsetX - 15) / width) * 100.0,
                100.0
              ),
              0
            )
          );
        };
  
        let barStillDown = false;
  
        barHoverBox.addEventListener(
          "touchstart",
          (e) => {
            barStillDown = true;
  
            calculateFill(e);
          },
          true
        );
  
        barHoverBox.addEventListener(
          "touchmove",
          (e) => {
            if (barStillDown) {
              calculateFill(e);
            }
          },
          true
        );
  
        barHoverBox.addEventListener(
          "mousedown",
          (e) => {
            barStillDown = true;
  
            calculateFill(e);
          },
          true
        );
  
      },
  
      actFromChannelList(program) {
        this.discardHls();
        this.destroyShaka();
        this.isPlayActive = true;        
        // this.initiateHls();
        this.actProgramPlay(program);
      }, 

      // initiateHls () {
      //   window.hlsPlayerObj = new window.Hls();
      //   window.hlsPlayerObj.attachMedia(this.hlsVideo)
      // },

      discardHls() {
        if(window.hlsPlayerObj){
          window.hlsPlayerObj.detachMedia();
          window.hlsPlayerObj.destroy();
          window.hlsPlayerObj = null;
        }
      },
      getLowPoster(item) {
        let returnImage = this.placeHolder.landScape;
        let index =
            item &&
            item.poster &&
            item.poster.findIndex((data) => {
            return data.postertype === "LANDSCAPE";
            });
        if (index > -1) {
            item.poster[index].filelist.filter((lowImg) => {
            if (lowImg && lowImg.quality === "LOW") {
                returnImage = lowImg.filename;
            }
            });
        }
        //  else {
        //   debugger;
        //   item.poster[0].filelist.filter((el) => {
        //     if (el.quality === "THUMBNAIL") {
        //       returnImage = el.filename;
        //     }
        //   });
        // }
        return returnImage;
      },
      actMuteUnmute (status) {
        this.ismuted = status;
        document.getElementById("live-channel-stream").muted = status;
      },
      actSelectedProgram (program) {
        console.log('program', program)
      }
    },
    mounted() {
      this.hlsVideo = document.getElementById("live-channel-stream");
      // window.hlsPlayerObj = new window.Hls();
      // this.initiateHls();
      // bind them together
      // window.hlsPlayerObj.attachMedia(this.hlsVideo);
      this.liveplayerpayload = this.liveplayerpayloadSingle;
      // this.actProgramPlay(this.liveplayerpayloadSingle);
      this.actProgramPlay(this.liveplayerpayload);
      // this.getAlltheChanelPagination();
  
      this.setupVolumn();
  
      this.actAutoHideEnabled = this.debounceFunction(
        this.actAutoHideEnabled,
        4000
      );
  
      this.actAutoHideEnabledMouseMove = this.throttleFunction(
        this.actAutoHideEnabledMouse,
        4000
      );
  
      // setTimeout(() => {
      //   // if (this.liveplayerpayload && this.liveplayerpayload.idepgchannel) {
      //   //   this.epgAPICall();
      //   // }
  
       
  
      //   let payLoad = {
      //     objecttype: "CHANEL",
      //   };
      //   eventBus.$emit("channelListData", payLoad);
  
      //   document
      //     .getElementsByClassName("player__wrapper")[0]
      //     .addEventListener("mousemove", this.actAutoHideEnabledMouseMove);
      // }, 100);
  
      const video = document.querySelector("#live-channel-stream");
      const slider = document.querySelector(".slider");
  
      video.addEventListener("timeupdate", () => {
        this.currentTime = video.currentTime.toFixed(2);
        this.totalDuration = video.duration.toFixed(2);
  
        slider.value = (video.currentTime / video.duration) * slider.max;
      });
  
      slider.addEventListener("change", () => {
        video.currentTime = (video.duration * slider.value) / slider.max;
      });
  
      setInterval(() => {
        this.formatAMPM();
      }, 1000);
    },
    beforeDestroy() {
      this.channelListActive = false;
      window.activeChanelProgramsListAll = [];
      this.errorMessage = null;
      eventBus.$off('playerError');
    },
    mixins: [PlaybackUtilities, Utility, dashPlayer, fairPlayer],
  };
</script>
        
<style lang="scss" scoped>
  @import "@/sass/_variables.scss";
  @import "@/sass/_components.scss";
  @import "./LivePlayer.scss";

  .hand {
    cursor: pointer;
};
</style>
      